<template>
  <div class="container">
    <div class="form-system-route">
      <div class="box-image">
        <div class="image-route">
          <img :src="require('@/assets/routeSystem.png')" alt="">
        </div>
      </div>
      <div class="field column">
        <div class="control">
          <label class="label">
            ຊື່ສາຍທາງ
            <span class="text-danger">* {{ errors.first("name") }}</span>
            <span class="text-danger">
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="name"
              v-validate="'required|max:191'"
              v-model="routeSystemObject.name"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
          />
        </div>
        <div class="control" style="margin-top: 8px">
          <label class="label">
            ສະຖານະ
            <span class="text-danger">* {{ errors.first("status") }}</span>
            <span class="text-danger">
            </span>
          </label>
          <div class="center con-switch" style="width: 40px;margin-top: 4px;">
            <vs-switch v-model="routeSystemObject.status"/>
          </div>
        </div>

        <div class="con-btns">
          <vs-button
              @click="ValidateForm"
              ref="button"
              flat
              :loading="isBtnLoading"
              class="form-btn-loading"
          >ບັນທຶກຂໍ້ມູນ
          </vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    }
  },
};
Validator.localize("en", dict);
export default {
  name: "Manage",
  props: ['routeSystemObject'],
  data() {
    return {
      isBtnLoading: false,
      server_errors: {
        name: "",
        status_id: "",
      },
      name: "",
      status: "",
    }
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.isBtnLoading = true;
      const items = {
        name: this.routeSystemObject.name,
        status: this.routeSystemObject.status === true ? 1 : 0
      }
      this.$http.post(`v1/auth/route-system/change-name-status/${this.routeSystemObject.id}`, items).then((res) => {
        if (res.data.success === true) {
          this.isBtnLoading = false;
          this.$emit('close');
          this.$emit('success');
        }
      }).catch((error) => {
        this.isBtnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    }
  },
}
</script>

<style scoped lang="scss">
.form-system-route {
  width: 400px;
  height: 320px;
  display: flex;
  flex-direction: column;

  .box-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image-route {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
</style>
