<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-house"></i>
          ລາຍການຂໍ້ມູນສາຍທາງ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
                placeholder="ຊື່ສາຍທາງ,ລະຫັດບາໂຄດ.."
            />
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="statusType" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="1">ສາຍທາງທີ່ເປີດ</option>
                <option value="0">ສາຍທາງທີ່ປິດ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" style="position: relative; margin-top: 20px">
          <div style="display: flex">
            <vs-button success>
              <span style="margin-right: 8px; !important;"><i class="fal fa-file-spreadsheet"></i></span>
              Export
            </vs-button>
            <vs-button @click="onPrint" dark>
              <span style="margin-right: 8px; !important;"><i class="far fa-print"></i></span>
              Print
            </vs-button>
            <vs-button @click="onCreate">
              <span style="margin-right: 8px"><i class="far fa-plus"></i></span>
              ເພີ່ມຂໍ້ມູນ
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດບາໂຄດ</vs-th>
            <vs-th>ຊື່ສາຍທາງ</vs-th>
            <vs-th>ຈຳນວນຮອບ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th>ຈັດການຂໍ້ມູນ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="item"
              v-for="(item, index) in $vs.getPage(routeSystem, page, max)">
            <vs-td>
              <span>{{ computedIndex(index) }}</span>
            </vs-td>
            <vs-td>
              <barcode
                  :height="25"
                  :width="1"
                  :text-margin="0"
                  :font-size="15"
                  :margin="0"
                  v-bind:value="item.barcode">
              </barcode>
            </vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ item.round_count }}</vs-td>
            <vs-td>
              <vs-button
                  circle
                  :class="item.status_id === 1 ? 'success-color' : 'warning-color' "
                  size="small"
              >
                <p style="font-size: 14px"> {{ item.status_id === 1 ? 'ເປີດ' : 'ປິດ' }}</p>
              </vs-button>
            </vs-td>
            <vs-td>{{ formatDate(item.created_at) }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="onEdit(item.id)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <CreateRouteSystem @close="close" @success="FetchData"/>
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <UpdateRouteSystem :routeSystemObject="routeSystemObject" @close="close" @success="FetchData"/>
      </template>
    </ModalEdit>

    <div hidden="{true}">
      <PrintRouteSystem id="printRoute" :array="myArray"/>
    </div>

  </div>
</template>
<script>
import Loading from "@/views/Loading";
import pagination from '@/Plugins/Paginations/Pagination';
import VueBarcode from "vue-barcode";
import moment from "moment";
import {Printd} from 'printd'
import * as helper from "@/Helpers/GetValue";
import CreateRouteSystem from "@views/SuperAdmin/RouteSystem/Dialog/CreateRouteSystem.vue";
import UpdateRouteSystem from "@views/SuperAdmin/RouteSystem/Dialog/UpdateRouteSystem.vue";
import PrintRouteSystem from "@views/SuperAdmin/RouteSystem/PrintRouteSystem.vue";

export default {
  components: {
    UpdateRouteSystem,
    Loading,
    pagination,
    barcode: VueBarcode,
    CreateRouteSystem,
    PrintRouteSystem,
  },

  data: () => ({
    myArray: [1, 2, 3, 4, 5],
    page: 1,
    max: 10,
    isLoading: true,
    searchItem: "",
    routeSystem: [],
    routeSystemId: "",
    routeSystemObject: {},
    statusType: "",

    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'statusType': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
  },
  methods: {
    async onPrint() {
      await this.setUpDataToPrint();
      await this.setPrinting();
    },

    async setUpDataToPrint() {
      try {
        const res = await this.$http.get(`v1/auth/route-system`);
        if (res.data.success === true) {
          this.$store.commit("setPrintRouteSystem", res.data.data);
        }
      } catch (e) {
        console.log(e)
      }
    },

    async setPrinting() {
      const d = new Printd();
      const styles = [
        `
       *{
          font-family: 'Poppins' ,phetsarath ot;
          margin:0px;
          padding:0px;
         }

        body, html {
          width:100%;
          margin:0px;
          padding:0px;
        }

        @page {
          width:100%;
          margin:0;
        }
      `
      ];
      const print = document.getElementById('printRoute');
      if (print) { // Add null check
        const printCallback = ({launchPrint}) => launchPrint();
        await d.print(print, styles, printCallback);
      } else {
        console.log("printInvoice element not found.");
      }
    },


    onCreate() {
      this.$store.commit("modalAdd_State", true);
    },
    async onEdit(id) {
      try {
        await this.fetchRouteSystemById(id);
        await this.onOpenModalUpdateRouteSystem();
      } catch (error) {
        // Handle any errors that occur during the async operations
        console.error(error);
      }
    },
    async fetchRouteSystemById(id) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await this.$http.get(`v1/auth/route-system/${id}`);
        if (response) {
          this.routeSystemObject = {
            ...response.data.data,
            status: response.data.data.status_id === 1 ? true : false,
          };
          // console.log(typeof (response.data.data))
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    },
    async onOpenModalUpdateRouteSystem() {
      this.$store.commit("modalEdit_State", true)
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },
    FetchData() {
      this.isLoading = true;
      this.fetchRouteSystem().then((res) => {
        setTimeout(() => {
          this.routeSystem = res.data.data.data;
          this.count = res.data.data.total;
          this.pagination = res.data.data;
          this.isLoading = false;
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    fetchRouteSystem(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('v1/auth/route-system/list', {
        params: {
          status: this.statusType ? this.statusType : "",
          query: this.searchItem ? this.searchItem : "",
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        },
      })
    },
  },
  computed: {
    computedIndex() {
      return (index) => {
        return (this.pagination.current_page - 1) * this.limit_page + index + 1;
      };
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.success-color {
  background: #04b704;
}

.warning-color {
  background: #ff0000;
}

</style>
