<template>
  <div class="container-print">
    <div v-for="item in routeSystem" :key="item.id"
         style="width: 100%;height: 100vh;display: flex;flex-direction: column;justify-content: center;align-items: center">
      <h3>{{ item.name }}</h3>
      <VueBarcode
          id="barcode"
          :value="item.barcode"
          :height="100"
          :text-margin="4"
          :font-size="28"
          :margin="8"
          :display-value="true"
      >
      </VueBarcode>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueBarcode from "vue-barcode";

export default {
  components: {
    VueBarcode,
  },
  computed: {
    ...mapGetters({
      routeSystem: 'getRouteSystem'
    })
  },
}
</script>
<style lang="scss">
</style>
